import Root from "./pages";

import { ThemeProvider } from "styled-components";
import theme from "./styles/theme/theme";
import GlobalStyle from "./styles/global";

import i18n from "./i18n";

i18n.changeLanguage("pt-BR");

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Root />
    </ThemeProvider>
  );
}

export default App;
