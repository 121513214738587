import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  position: fixed;
  height: 100%;
  top: 0px;
  right: 0px;
  width: 300px;

  right: ${(props) => (props.sidebar ? "0" : "-100%")};
  animation: showSidebar 0.4s;

  > svg {
    color: white;
    width: 40px;
    height: 40px;
    margin-top: 30px;
    margin-right: 20px;
    right: 0px;
    top: 0px;
    position: fixed;
    cursor: pointer;
  }

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 300px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 80px;
`;

export const ChangedLanguage = styled.div`
  display: flex;

  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 17px;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin: 5px 5px;

  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
`;
