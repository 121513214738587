import styled from "styled-components";

export const ButtonDefault = styled.button`
  width: 100%;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  height: 44px;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
`;
