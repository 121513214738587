const theme = {
  colors: {
    primary: "#070726",
    white: "#FFFFFF",
    subtitle: "#111111",
    subtitle_numbers: "#000000",
    range: "#f7f7f7",
    background_title: "#f4f4f4",
    subtitle_logos: "#6c6c6c",
    background_modal: "#f0f3f2",
  },
  typography: {
    title: "Inter",
  },
};

export default theme;
