import { useTheme } from "styled-components";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";
import axios from "axios";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";

import Button from "../button";
import InputMask from "react-input-mask";

import { FaPhoneAlt } from "react-icons/fa";
import { BsWhatsapp, BsFacebook, BsInstagram } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { AiFillLinkedin } from "react-icons/ai";

import logo from "../../assets/logo_footer.svg";

import {
  Container,
  Content,
  ContentLogo,
  ContentContact,
  ContentSocial,
  ContentButtons,
  ContentFooter,
  Copright,
  Developer,
  ContainerFooter,
  ContainerInformations,
  Link,
  Logo,
  Contact,
  DataInput,
  TitleContact,
  ContactCompany,
  ButtonClose,
  Message,
  ContentLoading,
} from "./styles";

import { Input } from "../input/styles";

const Footer = () => {
  const theme = useTheme();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenError(false);
  };

  const {
    handleSubmit: handleSubmitContact,
    control: controlContact,
    formState: { errors: errorsContact },
    register: registerContact,
    setValue,
  } = useForm();

  const {
    handleSubmit: handleSubmitCompany,
    control: controlCompany,
    formState: { errors: errorsCompany },
    register: registerCompany,
    reset: resetCompany,
  } = useForm();

  const useModal = () => {
    const [openModal, setOpenModal] = useState(null);
    const handleOpen = (modalName) => setOpenModal(modalName);
    const handleClose = () => setOpenModal(null);
    return { openModal, handleOpen, handleClose };
  };

  const { openModal, handleOpen, handleClose } = useModal();

  const url = "https://api.ymk.com.br/register";

  const recaptcha = async () => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        return reject(new Error("grecaptcha is not loaded"));
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LeOXGMqAAAAAOEq2cllcTFEtbY7m0TKnRT8poQd", {
            action: "submit",
          })
          .then(resolve)
          .catch(reject);
      });
    });
  };

  const submitFormData = async (data) => {
    try {
      const token = await recaptcha();

      const response = await axios.post(
        url,
        { ...data, recaptchaToken: token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data.success;
    } catch (error) {
      console.error("Erro ao enviar o formulário:", error);
      return false;
    }
  };

  const onSubmit = async (data) => {
    setButtonDisabled(true);

    const success = await submitFormData(data);

    if (success) {
      setOpenSuccess(true);
      setOpenError(false);
    } else {
      setOpenError(true);
    }

    setButtonDisabled(false);
  };

  const onSubmitCompany = async (data) => {
    setButtonDisabled(true);

    const success = await submitFormData(data);

    if (success) {
      setOpenSuccess(true);
      setOpenError(false);
      resetCompany();
      setValue("phone", "");
    } else {
      setOpenError(true);
    }

    setButtonDisabled(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1140px",
    height: 770,
    bgcolor: theme.colors.background_modal,
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 1160px)": {
      width: "95%",
    },
    "@media(max-width: 425px)": {
      p: 2,
      height: "95%",
    },
  };

  const { t } = useTranslation();

  return (
    <Container id="contact">
      <Content>
        <ContentLogo>
          <Logo src={logo} alt="logo_ymk" />
        </ContentLogo>

        <ContentContact>
          <ContainerInformations>
            <FaPhoneAlt color={theme.colors.primary} size={20} />
            <Link href="" onClick={(event) => event.preventDefault()}>
              (11) 3995-7808
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <BsWhatsapp color={theme.colors.primary} size={20} />
            <Link href="https://wa.me/5511983344053" target="_blank">
              (11) 98334-4053
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <IoMdMail color={theme.colors.primary} size={20} />
            <Link href="mailto: contato@ymk.com.br" target="_blank">
              contato@ymk.com.br
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <FaLocationDot color={theme.colors.primary} size={20} />
            <Link
              href="https://maps.app.goo.gl/pECPZp9AYsa99cnw8"
              target="_blank"
            >
              Praça Santo Agostinho, 70
              <br />
              Aclimação - São Paulo - SP
              <br />
              CEP 01533-070
            </Link>
          </ContainerInformations>
        </ContentContact>

        <ContentSocial>
          <ContainerInformations>
            <BsInstagram color={theme.colors.primary} size={20} />
            <Link href="https://www.instagram.com/grupoymk/" target="_blank">
              grupoymk
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <AiFillLinkedin color={theme.colors.primary} size={20} />
            <Link
              href="https://www.linkedin.com/company/grupoymk/"
              target="_blank"
            >
              grupoymk
            </Link>
          </ContainerInformations>
          <ContainerInformations>
            <BsFacebook color={theme.colors.primary} size={20} />
            <Link href="https://www.facebook.com/ymk.com.br" target="_blank">
              grupoymk
            </Link>
          </ContainerInformations>
        </ContentSocial>

        <ContentButtons>
          <Button
            color={theme.colors.white}
            background={theme.colors.primary}
            width={"100%"}
            onClick={() => handleOpen("associate")}
          >
            {t("translations.home.seja_nosso_parceiro")}
          </Button>
          <Button
            color={theme.colors.white}
            background={theme.colors.primary}
            width={"100%"}
            onClick={() => handleOpen("contact")}
          >
            {t("translations.home.entre_em_contato")}
          </Button>

          <Modal open={openModal === "associate"} onClose={handleClose}>
            <Box sx={style}>
              <ButtonClose onClick={handleClose}>X</ButtonClose>
              <ContactCompany onSubmit={handleSubmitCompany(onSubmitCompany)}>
                <TitleContact>
                  {t("translations.home.seja_nosso_parceiro")}
                </TitleContact>
                <DataInput>
                  <Input
                    placeholder={t("translations.home.nome")}
                    control={controlCompany}
                    {...registerCompany("name", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsCompany.name && (
                    <Message>{errorsCompany.name.message}</Message>
                  )}
                </DataInput>

                <DataInput>
                  <Input
                    placeholder={t("translations.home.empresa")}
                    control={controlCompany}
                    {...registerCompany("company", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsCompany.company && (
                    <Message>{errorsCompany.company.message}</Message>
                  )}
                </DataInput>

                <DataInput>
                  <Input
                    placeholder={t("translations.home.email")}
                    control={controlCompany}
                    {...registerCompany("email", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsCompany.email && (
                    <Message>{errorsCompany.email.message}</Message>
                  )}
                </DataInput>

                <DataInput>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder={t("translations.home.telefone")}
                    control={controlCompany}
                    {...registerCompany("phone", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                    style={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: theme.colors.white,
                      border: "none",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                    }}
                  />
                  {errorsCompany.phone && (
                    <Message>{errorsCompany.phone.message}</Message>
                  )}
                </DataInput>

                <DataInput className="message">
                  <Input
                    placeholder={t("translations.home.mensagem")}
                    control={controlCompany}
                    {...registerCompany("message", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsCompany.message && (
                    <Message>{errorsCompany.message.message}</Message>
                  )}
                </DataInput>

                <Button
                  background={theme.colors.primary}
                  color={theme.colors.white}
                  type="submit"
                >
                  {t("translations.home.enviar")}
                </Button>
              </ContactCompany>
              <Snackbar
                open={openSuccess}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                  Formulário enviado com sucesso!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openError}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  Erro no envio do formulário!
                </Alert>
              </Snackbar>
            </Box>
          </Modal>

          <Modal open={openModal === "contact"} onClose={handleClose}>
            <Box sx={style}>
              <ButtonClose onClick={handleClose}>X</ButtonClose>
              <Contact onSubmit={handleSubmitContact(onSubmit)}>
                <TitleContact>
                  {t("translations.home.entre_em_contato")}
                </TitleContact>

                <DataInput>
                  <Input
                    placeholder={t("translations.home.nome")}
                    control={controlContact}
                    {...registerContact("name", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsContact.name && (
                    <Message>{errorsContact.name.message}</Message>
                  )}
                </DataInput>

                <DataInput>
                  <Input
                    placeholder={t("translations.home.email")}
                    control={controlContact}
                    {...registerContact("email", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsContact.email && (
                    <Message>{errorsContact.email.message}</Message>
                  )}
                </DataInput>

                <DataInput>
                  <InputMask
                    mask="(99) 99999-9999"
                    placeholder={t("translations.home.telefone")}
                    control={controlContact}
                    {...registerContact("phone", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                    style={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: theme.colors.white,
                      border: "none",
                      paddingLeft: "10px",
                      borderRadius: "4px",
                    }}
                  />
                  {errorsContact.phone && (
                    <Message>{errorsContact.phone.message}</Message>
                  )}
                </DataInput>

                <DataInput className="message">
                  <Input
                    placeholder={t("translations.home.mensagem")}
                    control={controlContact}
                    {...registerContact("message", {
                      required: `${t("translations.home.obrigatorio")}`,
                    })}
                  />
                  {errorsContact.message && (
                    <Message>{errorsContact.message.message}</Message>
                  )}
                </DataInput>

                <Button
                  background={theme.colors.primary}
                  color={theme.colors.white}
                  type="submit"
                  disabled={buttonDisabled}
                >
                  {t("translations.home.enviar")}
                </Button>
              </Contact>

              <Snackbar
                open={openSuccess}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="success" sx={{ width: "100%" }}>
                  {t("translations.home.form_sucesso")}
                </Alert>
              </Snackbar>
              <Snackbar
                open={openError}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  {t("translations.home.form_erro")}
                </Alert>
              </Snackbar>
            </Box>
          </Modal>
        </ContentButtons>
      </Content>

      <ContentFooter>
        <ContainerFooter>
          <Copright>
            &copy; {new Date().getFullYear()} {t("translations.home.grupo")} YMK
            | {t("translations.home.direitos_reservados")}
          </Copright>

          <Developer>
            {t("translations.home.desenvolvido")}
            {"  "}
            <strong>
              <a
                style={{ textDecoration: "none", color: theme.colors.primary }}
                href="https://ypb.com.br/"
                target="__blank"
              >
                YPB MARKETING DIGITAL
              </a>
            </strong>
          </Developer>
        </ContainerFooter>
      </ContentFooter>
      {buttonDisabled && (
        <ContentLoading>
          <ReactLoading
            type="spin"
            color="black"
            height="100px"
            width="100px"
          />
        </ContentLoading>
      )}
    </Container>
  );
};

export default Footer;
