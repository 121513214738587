import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.range};
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 950px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1140px;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-size: 36px;
  width: 290px;
  @media (max-width: 950px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const ContentNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 950px) {
    align-items: center;
    width: 320px;
    padding: 10px;
  }
  margin: 10px;
`;

export const Number = styled.label`
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-size: 36px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-size: 15px;
  text-align: center;
  color: ${({ theme }) => theme.colors.subtitle_numbers};
`;
