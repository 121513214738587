import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Content,
  Title,
  ContentNumber,
  Number,
  Description,
} from "./styles";
import { useTranslation } from "react-i18next";

const YMKnumbers = () => {
  const [numberSales, setNumberSales] = useState(0);
  const [numberPatrimony, setNumberPatrimony] = useState(0);
  const [numberYears, setNumberYears] = useState(0);
  const [numberProjects, setNumberProjects] = useState(0);

  const finalNumberSales = 75;
  const finalNumberPatrimony = 1000;
  const finalNumberYears = 50;
  const finalNumberProjects = 3;

  const animatedNumbersRef = useRef(null);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const interval = setInterval(() => {
          setNumberSales((prevNumber) =>
            prevNumber < finalNumberSales ? prevNumber + 4 : finalNumberSales
          );
          setNumberPatrimony((prevNumber) =>
            prevNumber < finalNumberPatrimony
              ? prevNumber + 35
              : finalNumberPatrimony
          );
          setNumberYears((prevNumber) =>
            prevNumber < finalNumberYears ? prevNumber + 2 : finalNumberYears
          );
          setNumberProjects((prevNumber) =>
            prevNumber < finalNumberProjects
              ? prevNumber + 0.1
              : finalNumberProjects
          );
        }, 70);

        return () => clearInterval(interval);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.7,
    });

    if (animatedNumbersRef.current) {
      observer.observe(animatedNumbersRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const { t } = useTranslation();

  return (
    <Container ref={animatedNumbersRef}>
      <Content>
        <Title>{t("translations.home.nossos_numeros")}</Title>
        <ContentNumber>
          <Number>{numberSales} bi</Number>
          <Description>{t("translations.home.imobiliarios")}</Description>
        </ContentNumber>
        <ContentNumber>
          <Number>+ {numberPatrimony}</Number>
          <Description>{t("translations.home.incorporadoras")}</Description>
        </ContentNumber>
        <ContentNumber>
          <Number>+ {Math.round(numberYears)}</Number>
          <Description>{t("translations.home.instituicoes")}</Description>
        </ContentNumber>
        <ContentNumber>
          <Number>+{numberProjects.toFixed(0)} bi</Number>
          <Description>{t("translations.home.vgv")}</Description>
        </ContentNumber>
      </Content>
    </Container>
  );
};

export default YMKnumbers;
