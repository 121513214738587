import Footer from "../../components/footer";
import Header from "../../components/header";
import { Container, ContentText, Text } from "./styles";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Header backgroundColor="#070726" />
      <ContentText>
        <Text>
          O GRUPO YMK assume o compromisso de respeitar a legislação em vigor
          relativa à proteção de dados pessoais e a não ceder, sob qualquer
          forma ou condição, esta informação a outras entidades.
          <br /> Nós coletamos os seguintes tipos de dados: <br />
          • Dados Pessoais;
          <br /> • de Imóveis
          <br /> Utilizamos os dados coletados tão somente para o contato direto
          com o usuário, através de ligação telefônica, email, whatsapp, para
          comunicados relacionados ao GRUPO YMK e também para oferecimento de
          produtos e serviços.
          <br /> Em hipótese alguma o GRUPO YMK compartilha os Dados pessoais ou
          de Imóveis com Parceiros ou outras pessoas sem o consentimento prévio
          e expresso do usuário.
          <br /> Qualquer pessoa que se cadastrar em qualquer site ou Landing
          Page do GRUPO YMK terá seus dados protegidos por sistema de segurança
          e, caso queira acessá-los ou mesmo retirá-los do sistema, basta enviar
          um email para contato@ymk.com.br.
          <br /> Utilizamos cookies e tecnologias semelhantes como pixels e tags
          para certificar que os serviços prestados e oferecidos estão de acordo
          com o melhor padrão de desempenho esperado pelo usuário, tais como
          ações de marketing e estudo do comportamento do usuário nos sites do
          GRUPO YMK.
          <br /> Todos os cookies coletados jamais serão utilizados para
          qualquer propósito diverso dos expressamente previstos nesta Política
          de Privacidade.
          <br /> O GRUPO YMK assume, ainda, o compromisso de adotar as medidas
          de segurança necessárias de forma a garantir a proteção dos dados
          pessoais dos usuários contra o seu eventual uso abusivo ou contra o
          acesso não autorizado.
          <br /> O GRUPO YMK poderá alterar esta política de privacidade e
          segurança de dados a qualquer momento.
          <br /> Toda alteração na presente Política de Privacidade e Segurança
          de Dados será veiculada neste espaço. Portanto, solicitamos a leitura
          periódica desta Política como meio de se cientificar sobre a forma que
          o Dieta e Saúde coleta, trata e protege as suas informações.
          <br />
          Caso haja qualquer dúvida ou sugestão sobre esta Política de
          Privacidade e Segurança de Dados, escreva para: contato@ymk.com.br.
        </Text>
      </ContentText>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
