import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  padding-left: 10px;
  border-radius: 4px;
`;
