import styled from "styled-components";

export const Container = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentText = styled.section`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  text-align: justify;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 1250px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  font-family: ${({ theme }) => theme.typography.title};
`;
