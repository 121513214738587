import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { FaTimes } from "react-icons/fa";

import { Container, Content, ChangedLanguage } from "./styles";

import SidebarItem from "./sideBarItem";
import BasicMenu from "../dropDown";

const SideBar = ({ active }) => {
  const closeSideBar = () => {
    active(false);
  };

  const { t } = useTranslation();

  return (
    <Container sidebar={active ? "true" : "false"}>
      <FaTimes onClick={closeSideBar} />
      <Content>
        <NavLink to={"/quem-somos"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={`${t("translations.home.quem_somos")}`} />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={`${t("translations.home.unidades")}`} />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={`${t("translations.home.cases")}`} />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={`${t("translations.home.contato")}`} />
        </NavLink>
        <NavLink to={"/"} style={{ textDecoration: "none" }}>
          <SidebarItem Text={`${t("translations.home.investidor")}`} />
        </NavLink>
        <ChangedLanguage>
          <BasicMenu
            width="100%"
            style={{
              height: "100%",
              display: "flex",
              width: "100%",
              alignItems: "flex-start",
            }}
          />
        </ChangedLanguage>
      </Content>
    </Container>
  );
};

export default SideBar;
