import React from "react";

import { ButtonDefault } from "./styles";

const Button = ({
  children,
  color,
  background,
  width,
  onClick,
  type,
  disabled,
}) => {
  return (
    <ButtonDefault
      style={{ color: color, background: background, width: width }}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </ButtonDefault>
  );
};

export default Button;
