import styled from "styled-components";

export const StyledDescription = styled.label``;

export const Title = styled.h1`
  font-size: 18px;
`;

export const ContainerMultiplataform = styled.div`
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media (max-width: 485px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 485px) {
    width: 100%;
    text-align: center;
  }
`;

export const TextMultiplataform = styled.label`
  font-size: 57px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;

  @media (max-width: 485px) {
    font-size: 47px;
  }
  @media (max-width: 445px) {
    font-size: 42px;
  }
  @media (max-width: 370px) {
    font-size: 32px;
  }
`;

export const TextRemaining = styled.label`
  font-size: 52px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-weight: 400;
  @media (max-width: 485px) {
    font-size: 42px;
  }
  @media (max-width: 445px) {
    font-size: 37px;
  }
  @media (max-width: 370px) {
    font-size: 27px;
  }
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  object-fit: cover;
  z-index: -1;
`;

export const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  z-index: 0;
  border-bottom: 10px solid ${({ theme }) => theme.colors.primary};
`;

export const ContentAlert = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleAlert = styled.h1`
  font-size: 30px;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  width: 100%;
  font-weight: 400;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  @media (max-width: 840px) {
    font-size: 25px;
  }
  @media (max-width: 375px) {
  }
`;

export const Line = styled.div`
  height: 1px;
  width: 90%;
  background-color: #ededed;
  margin: 0 auto;
  margin-bottom: 30px;
  @media (max-width: 397px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const TextAlert = styled.label`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  text-align: justify;
  padding: 20px;
  @media (max-width: 840px) {
    padding: 15px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
  max-height: 100%;
`;

export const ButtonClose = styled.button`
  background-color: black;
  color: white;
  height: 22px;
  width: 22px;
  border: none;
  position: absolute;
  right: 25px;
  cursor: pointer;
  font-size: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
