import React from "react";

import Footer from "../../components/footer";
import Header from "../../components/header";

import { Container, Media, Frame } from "./styles";
import { useTheme } from "styled-components";

const WhoUs = () => {
  const theme = useTheme();

  return (
    <Container>
      <Header backgroundColor={theme.colors.primary} />
      <Media>
        <Frame
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-Kl6qFZvqhE?si=CiWozia_fRIS5eiL&autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></Frame>
      </Media>

      <Footer />
    </Container>
  );
};

export default WhoUs;
