import styled from "styled-components";

export const Container = styled.footer`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-top: 5px solid ${({ theme }) => theme.colors.primary};
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 1140px;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const ContentLogo = styled.figure`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 539px) {
    height: 100%;
    padding: 10px;
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: 135px;
  height: 95px;
`;

export const ContentContact = styled.div`
  width: 300px;
  height: 200px;
  @media (max-width: 539px) {
    height: 100%;
    padding: 10px;
    width: 100%;
  }
`;

export const ContentSocial = styled.div`
  width: 200px;
  height: 200px;
  @media (max-width: 539px) {
    height: 100%;
    padding: 10px;
    width: 100%;
  }
`;

export const ContentButtons = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 20px;
  @media (max-width: 539px) {
    height: 100%;
    padding: 10px;
    width: 100%;
  }
`;

export const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  max-height: 100%;
  position: relative;
  height: 70px;
`;

export const Copright = styled.label`
  font-family: ${({ theme }) => theme.typography.title};
  font-size: 12px;
  text-align: center;
`;

export const Developer = styled.label`
  font-family: ${({ theme }) => theme.typography.title};
  font-size: 12px;
  text-align: center;
`;

export const ContainerFooter = styled.div`
  width: 1140px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }
  gap: 10px;
`;

export const ContainerInformations = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.subtitle_logos};
  font-size: 15px;
  font-family: ${({ theme }) => theme.typography.title};
`;

export const TitleContact = styled.h1`
  font-family: ${({ theme }) => theme.typography.title};
  padding-bottom: 10px;
  @media (max-width: 400px) {
    font-size: 20px;
  }
`;

export const Contact = styled.form`
  width: 600px;
  height: 700px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  @media (max-width: 620px) {
    padding: 20px 20px;
    width: 100%;
  }
  @media (max-width: 550px) {
    padding: 0px 0px;
    width: 100%;
  }
  @media (max-width: 500px) {
    height: 90%;
  }
`;

export const ContactCompany = styled.form`
  width: 600px;
  height: 800px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  @media (max-width: 620px) {
    padding: 20px 20px;
    width: 100%;
  }
  @media (max-width: 550px) {
    padding: 0px 0px;
    width: 100%;
    height: 90%;
    gap: 10px;
  }
`;

export const DataInput = styled.div`
  width: 100%;
  height: 76px;

  &.message {
    height: 190px;
    & :first-child {
      height: 170px;
      text-align: start;
    }
  }
  @media (max-width: 500px) {
    height: 65px;
  }
`;

export const ButtonClose = styled.button`
  background-color: black;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
`;

export const Message = styled.span`
  color: red;
  font-family: ${({ theme }) => theme.typography.title};
  font-size: 13px;
  height: 25px;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    height: 15px;
  }
`;

export const ContentLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
