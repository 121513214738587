import styled from "styled-components";

export const ContentHeader = styled.header`
  height: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const Container = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentLogo = styled.figure`
  height: 100%;
  width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Logo = styled.img`
  width: 105px;
  height: 73px;
`;

export const Navigation = styled.nav`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: end;
  }
`;
export const ContentList = styled.ul`
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const List = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-size: 16px;
  cursor: pointer;
`;

export const ContainerMultiplataform = styled.div`
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media (max-width: 485px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 485px) {
    width: 100%;
    text-align: center;
  }
`;

export const TextMultiplataform = styled.label`
  font-size: 57px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  @media (max-width: 485px) {
    font-size: 47px;
  }
  @media (max-width: 445px) {
    font-size: 42px;
  }
  @media (max-width: 370px) {
    font-size: 32px;
  }
`;

export const TextRemaining = styled.label`
  font-size: 52px;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-weight: 400;
  @media (max-width: 485px) {
    font-size: 42px;
  }
  @media (max-width: 445px) {
    font-size: 37px;
  }
  @media (max-width: 370px) {
    font-size: 27px;
  }
`;

export const SideBarContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
  > svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
`;
