import styled from "styled-components";

export const Container = styled.section`
  height: 100%;
  width: 100%;
`;

export const Media = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1140px;
  border: 1px solid;
  border-radius: 4px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary};
`;

export const Frame = styled.iframe`
  height: 600px;
  width: 100%;
`;
