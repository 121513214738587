import styled from "styled-components";
import imoveis from "../../assets/imoveis.webp";
import corporate from "../../assets/corporate.webp";
import privated from "../../assets/private.webp";
import credit from "../../assets/credit.webp";
import agro from "../../assets/agro.webp";

import imoveis_title from "../../assets/imoveis_title.webp";
import corporate_title from "../../assets/corporate_title.webp";
import private_title from "../../assets/private_title.webp";
import credit_title from "../../assets/credit_title.webp";
import agro_title from "../../assets/agro_title.webp";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`;

export const Content = styled.div`
  width: 1140px;
  padding: 35px 0px 35px;

  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const TitlePortfolio = styled.h1`
  font-size: 56px;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-weight: bold;
`;

export const Companies = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentPortfolio = styled.div`
  width: 1140px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media (max-width: 1180px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  padding: 20px 0px 30px;
`;

export const ContentTitle = styled.div`
  background-repeat: no-repeat;

  &.content-1 {
    background-image: url(${imoveis_title});
  }

  &.content-2 {
    background-image: url(${corporate_title});
    background-position: left center;

    @media (max-width: 500px) {
      background-size: 100%;
    }
  }

  &.content-3 {
    background-image: url(${private_title});
    background-position: left center;
  }

  &.content-4 {
    background-image: url(${credit_title});
    background-position: left center;
  }

  &.content-5 {
    background-image: url(${agro_title});
    background-position: left center;
  }
`;

export const BackgroundImage = styled.div`
  width: 50%;
  &.content-1 {
    background-image: url(${imoveis});
    background-size: cover;
    @media (max-width: 590px) {
      display: none;
    }
  }
  &.content-2 {
    background-image: url(${corporate});
    background-size: cover;
    @media (max-width: 590px) {
      display: none;
    }
  }
  &.content-3 {
    background-image: url(${privated});
    background-size: cover;
    @media (max-width: 590px) {
      display: none;
    }
  }
  &.content-4 {
    background-image: url(${credit});
    background-size: cover;
    @media (max-width: 590px) {
      display: none;
    }
  }
  &.content-5 {
    background-image: url(${agro});
    background-size: cover;
    @media (max-width: 590px) {
      display: none;
    }
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  box-shadow: 0 0 10px rgba(7, 7, 38, 0.4);
  border-radius: 4px;

  &.content-1 {
    height: 350px;

    @media (max-width: 590px) {
      flex-direction: column;
    }

    & :nth-child(6) {
      border-bottom: none;
    }

    @media (max-width: 768px) {
      height: 500px;
    }
    @media (max-width: 768px) {
      height: 100%;
    }

    p {
      width: 65%;
      @media (max-width: 768px) {
        padding: 10px;
        display: flex;
        align-items: flex-start;
      }
      @media (max-width: 590px) {
        width: 100%;
      }
    }
  }

  &.content-2 {
    height: 253px;
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 590px) {
      height: 100%;
    }
    & :first-child {
      background-color: ${({ theme }) => theme.colors.white};

      @media (max-width: 590px) {
        flex-direction: column;
      }
    }
    & :nth-child(2) {
      display: flex;
      gap: 10px;
      & :first-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 590px) {
        flex-direction: column;
      }
    }
    & :nth-child(3) {
      border-bottom: none;
      @media (max-width: 590px) {
        flex-direction: column;
      }
    }
  }
  &.content-3 {
    height: 180px;
    justify-content: flex-end;

    @media (max-width: 590px) {
      height: 100%;
    }
    & :first-child {
      background-color: ${({ theme }) => theme.colors.white};
      @media (max-width: 590px) {
        flex-direction: column;
      }
    }
    p {
      width: 100%;
      @media (max-width: 768px) {
        padding: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
  &.content-4 {
    height: 180px;
    justify-content: flex-end;

    @media (max-width: 590px) {
      height: 100%;
    }
    & :first-child {
      background-color: ${({ theme }) => theme.colors.white};
      @media (max-width: 590px) {
        flex-direction: column;
      }
    }
    p {
      width: 100%;
      @media (max-width: 768px) {
        padding: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
  &.content-5 {
    height: 180px;
    justify-content: flex-end;

    @media (max-width: 590px) {
      height: 100%;
    }
    & :first-child {
      background-color: ${({ theme }) => theme.colors.white};
      @media (max-width: 590px) {
        flex-direction: column;
      }
    }
    p {
      width: 100%;
      @media (max-width: 768px) {
        padding: 10px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
`;

export const ContainerDescription = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Services = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (max-width: 590px) {
    flex-direction: column;
  }

  &.content-1 {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};

    &:nth-child(5) {
      border-bottom: none;
    }
  }
  &.content-2 {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const ContainerLogo = styled.figure`
  width: 200px;
  height: 62px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img``;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.typography.title}, sans-serif;
  font-size: 15px;
  width: 350px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;

  &.content-1 {
    @media (max-width: 590px) {
      width: 100%;
      align-items: flex-start;
      padding-inline-start: 20px;
      padding-bottom: 15px;
    }
  }

  &.content-2 {
    width: 100%;
    align-items: center;
    padding-inline-start: 20px;
    padding-bottom: 15px;
    display: flex;

    @media (max-width: 590px) {
      align-items: flex-start;
    }
  }

  &.content-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    @media (max-width: 590px) {
      width: 100%;
      padding-inline-start: 20px;
      padding-bottom: 15px;
    }
  }
  &.content-4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    @media (max-width: 590px) {
      width: 100%;
      padding-inline-start: 20px;
      padding-bottom: 15px;
    }
  }
  &.content-5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    @media (max-width: 590px) {
      width: 100%;
      padding-inline-start: 20px;
      padding-bottom: 15px;
    }
  }
`;
